import { useCurrentUser } from '@capturi/core'
import { usePageTitle } from '@capturi/react-utils'
import { useUsers } from '@capturi/stores'
import { List, ListItem, PageHeading } from '@capturi/ui-components'
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  SkeletonCircle,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import { MdClose, MdSearch } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import routes from '../routes'

const randomNumber = (min: number, max: number): number =>
  Math.random() * (max - min) + min

const CoachingPage: React.FC = () => {
  usePageTitle(t`Employees`)
  const { users, isLoading } = useUsers()
  const { isTeamLead } = useCurrentUser()
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')

  const filteredUsers = users.filter(
    (user) =>
      (isTeamLead ? user.isSubordinate : true) &&
      user.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <Box>
      <Flex justify="space-between" mb={6}>
        <PageHeading>
          <Trans>Employees</Trans>
        </PageHeading>
        <InputGroup width="30%" maxW="200px" size="sm">
          <InputLeftElement pointerEvents="none">
            <Icon fontSize="14px" as={MdSearch} />
          </InputLeftElement>
          <Input
            placeholder={t`Search...`}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
            borderColor="gray.200"
            borderRadius={4}
          />
          {searchTerm && searchTerm.length > 0 ? (
            <InputRightElement cursor="pointer">
              <Icon
                fontSize="14px"
                as={MdClose}
                onClick={() => setSearchTerm('')}
              />
            </InputRightElement>
          ) : null}
        </InputGroup>
      </Flex>

      <List>
        {filteredUsers.length === 0 && (
          <Text color="textMuted" textAlign="center">
            <Trans>No users found</Trans>
          </Text>
        )}
        {filteredUsers.map((u) => (
          <ListItem
            key={u.uid}
            button
            onClick={() => navigate(routes.user(u.uid))}
            hasDivider
          >
            {isLoading ? (
              <>
                <SkeletonCircle size="24px" mr="2" />
                <Skeleton height="15px" width={`${randomNumber(80, 200)}px`} />
              </>
            ) : (
              <>
                <Avatar
                  name={u.name}
                  src={u.profileImage?.key}
                  title={u.name}
                  aria-label={u.name}
                  size="xs"
                />
                <Box mx="2">{u.name}</Box>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default CoachingPage
